import { Injectable, ErrorHandler, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DataService } from "./data.service";
import { UntypedFormControl } from "@angular/forms";
import { AuthService } from "./auth.service";
import { environment } from "../environments/environment";
import { switchMap } from "rxjs";
import moment from "moment";
import * as StackTrace from "stacktrace-js";

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  _dataService = inject(DataService);
  _authService = inject(AuthService);
  http = inject(HttpClient);

  handleError(error: any): void {
    console.log("HANDLER: ",error);
    if (environment.production && navigator.onLine) {
      StackTrace.fromError(error).then((stackframes) => {
        const stackString = stackframes.map((sf) => sf.toString()).join("\n");

        this.http
          .post(this._dataService.dbBase + "api.php/records/errors", {
            message: error?.message ?? "",
            stackTrace: stackString ?? "",
            transaction: error?.name ?? "",
            data: JSON.stringify(error) ?? "",
            formData: JSON.stringify(error?.headers) ?? "",
            date:
              new UntypedFormControl(moment()).value.format(
                "YYYY-MM-DD HH:mm:ss"
              ) ?? "",
            dbTable: this._dataService.dbTable ?? "",
            user: this._authService.loggedInUserValue.fullname ?? "",
          })
          .subscribe({
            complete: () => {
              console.log("Error sent to backend successfully.");
              this.push(error?.message);
            },
            error: (apiError) => {
              console.error("Failed to send error to backend:", apiError);
            },
          });
      });
    }
  }
  push(error?: string) {
    this.http
      .get(
        "https://tekoplast.az/docktr/api/?getTokens&uid=1TgHpEOspfZmDhanm8m1XLgm29u1"
      )
      .pipe(
        switchMap((res) => {
          let tokens = res[0].fcmToken;
          let requestData = {
            tokens: JSON.stringify(tokens),
            title: "JJ Accounting Error",
            body: error || null,
            url: "https://jj.tekoplast.az",
          };
          return this.http.post(
            "https://tekoplast.az/docktr/api/?pushNotification",
            requestData
          );
        })
      )
      .subscribe();
  }
}
