import { Injectable, inject, signal } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  defer,
  distinctUntilChanged,
  from,
  merge,
  of,
  switchMap,
  tap,
} from "rxjs";
import { ICustomer } from "../shared/models/customer.interface";
import { IApiResult } from "../shared/models/apiresult.interface";
import { DataService } from "../data.service";
import { MatDialog } from "@angular/material/dialog";
import { NewCustomerDialogComponent } from "../dialogs/customer-dialog/customer-dialog.component";
import { ToastrService } from "ngx-toastr";
import { db } from "src/app/db.service";
import { SyncService } from "../sync.service";
import { FormBuilder } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class CustomersService {
  _formBuilder = inject(FormBuilder);
  _dataService = inject(DataService);
  _syncService = inject(SyncService);
  http = inject(HttpClient);
  toastr = inject(ToastrService);
  dialog = inject(MatDialog);
  selectedCustomerId: number;
  chipSelected: BehaviorSubject<string> = new BehaviorSubject("");
  dataLoading = signal(false);
  selectedCustomerGroups = this._formBuilder.group({});
  sumDebit: number = this._dataService.getLS("sumDebit") || 0;
  sumKredit: number = this._dataService.getLS("sumKredit") || 0;
  sumDebitSig = signal(this.sumDebit);
  sumKreditSig = signal(this.sumKredit);

  baseUrl: string = "https://tekoplast.az/hesabat/api.php/records/customers/";

  constructor() {
    let formControls = {};
    this._dataService.customerGroups().forEach((customerGroup) => {
      formControls[customerGroup.id] = false;
    });
    this.selectedCustomerGroups = this._formBuilder.group(formControls);
  }

  customerDialog(customerData?) {
    let data = null;
    if (customerData) {
      if (customerData?.customerId) {
        data = customerData
          ? this._dataService
              .customers()
              .find((c) => c.id == customerData?.customerId)
          : null;
        // CUSTOMER DEBIT CREIT DATA BELOW
        data = {
          ...data,
          customerData: customerData,
        };
      }
      if (customerData?.customerGroupId)
        data = {
          ...data,
          customerGroupId: customerData?.customerGroupId,
        };
    }

    const dialogRef = this.dialog.open(NewCustomerDialogComponent, {
      width: data ? "800px" : "500px",
      data: { data: data },
      closeOnNavigation: true,
      disableClose: this._dataService.mobileDevice
        ? false
        : customerData
        ? false
        : true,
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.selectedCustomerId = res.id;
        }
      },
      () => {
        this.toastr.error("Xəta baş verdi. Zəhmət olmasa yenidən cəhd edin");
      }
    );
  }

  getCustomersBalance() {
    const localData = this._dataService.getLS("debitorsCreditors") || [];
    const localObservable = defer(() => of(localData));

    const combinedObservable = combineLatest([
      this._dataService.dateChanged,
      this._syncService.newDataSynced.pipe(distinctUntilChanged()),
    ]).pipe(
      tap(() => {
        this.dataLoading.set(true);
      }),
      switchMap(() => {
        const startDate = new Date(
          this._dataService.startDate.value.format("YYYY-MM-DD")
        );
        const endDate = this._dataService.endDate.value.format("YYYY-MM-DD");

        return from(
          Promise.all([
            db.stock.where("date").belowOrEqual(endDate).toArray(),
            db.sells.where("date").belowOrEqual(endDate).toArray(),
            db.bank.where("date").belowOrEqual(endDate).toArray(),
          ]).then(([stocks, sells, bank]) => {
            const customerData: CustomerData = {};
            this._dataService.customers().forEach((item) => {
              customerData[item.id] = {
                customerId: item.id,
                customerName: item.name || "",
                sellsTotal: 0,
                sellsBefore: 0,
                incomeTotal: 0,
                incomeBefore: 0,
                outgoingTotal: 0,
                outgoingBefore: 0,
                stocksTotal: 0,
                stocksBefore: 0,
                incomeWithoutBalance: 0,
                outgoingWithoutBalance: 0,
                earlyBalance: 0, // initialize earlyBalance
              };
            });

            const processData = (data: any[], type: string) => {
              data.forEach((item) => {
                if (item.customerId && customerData[item.customerId]) {
                  const isAfterStartDate = new Date(item.date) >= startDate;
                  const value = item.quantity * item.price;
                  switch (type) {
                    case "stock":
                      customerData[item.customerId].stocksTotal +=
                        isAfterStartDate &&
                        value >= 0 &&
                        item.tType !== "Production"
                          ? value
                          : 0;
                      customerData[item.customerId].sellsTotal +=
                        isAfterStartDate &&
                        value < 0 &&
                        item.tType !== "Production"
                          ? -value
                          : 0;
                      customerData[item.customerId].stocksBefore +=
                        !isAfterStartDate &&
                        value >= 0 &&
                        item.tType !== "Production"
                          ? value
                          : 0;
                      customerData[item.customerId].sellsBefore +=
                        !isAfterStartDate &&
                        value < 0 &&
                        item.tType !== "Production"
                          ? -value
                          : 0;
                      break;

                    case "sell":
                      customerData[item.customerId].sellsTotal +=
                        isAfterStartDate && value >= 0 ? value : 0;
                      customerData[item.customerId].stocksTotal +=
                        isAfterStartDate && value < 0 ? -value : 0;
                      customerData[item.customerId].sellsBefore +=
                        !isAfterStartDate && value >= 0 ? value : 0;
                      customerData[item.customerId].stocksBefore +=
                        !isAfterStartDate && value <= 0 ? -value : 0;
                      break;

                    case "bank":
                      const bankGroup = this._dataService
                        .bankGroups()
                        .find(
                          (b) => b.id.toString() === item.bankGroupId.toString()
                        );

                      if (item.income > 0) {
                        customerData[item.customerId].incomeTotal +=
                          isAfterStartDate ? item.income : 0;
                        customerData[item.customerId].incomeWithoutBalance +=
                          isAfterStartDate && bankGroup?.includeInBalance
                            ? item.income
                            : 0;
                        customerData[item.customerId].incomeBefore +=
                          !isAfterStartDate && bankGroup?.includeInBalance
                            ? item.income
                            : 0;
                      } else if (item.outgoings > 0) {
                        customerData[item.customerId].outgoingTotal +=
                          isAfterStartDate ? item.outgoings : 0;
                        customerData[item.customerId].outgoingWithoutBalance +=
                          isAfterStartDate && bankGroup?.includeInBalance
                            ? item.outgoings
                            : 0;
                        customerData[item.customerId].outgoingBefore +=
                          !isAfterStartDate && bankGroup?.includeInBalance
                            ? item.outgoings
                            : 0;
                      }

                      break;
                  }
                }
              });
            };

            this.sumDebit = 0;
            this.sumKredit = 0;

            processData(stocks, "stock");
            processData(sells, "sell");
            processData(bank, "bank");

            Object.values(customerData).forEach((data) => {
              data.stocksTotal = parseFloat(data.stocksTotal.toFixed(2));
              data.sellsTotal = parseFloat(data.sellsTotal.toFixed(2));
              data.incomeTotal = parseFloat(data.incomeTotal.toFixed(2));
              data.outgoingTotal = parseFloat(data.outgoingTotal.toFixed(2));
            });

            const filteredResult = Object.values(customerData);
            const result = filteredResult.map((data) => {
              const customer = this._dataService
                .customers()
                .find((c) => c.id == data.customerId) || {
                balance: 0,
                customerGroupId: null,
              };
              const balanceWithoutB =
                data.sellsTotal -
                data.stocksTotal -
                data.incomeWithoutBalance +
                data.outgoingWithoutBalance;
              const balance =
                data.sellsTotal -
                data.stocksTotal -
                data.incomeTotal +
                data.outgoingTotal;
              const balanceBefore =
                data.sellsBefore -
                  data.stocksBefore -
                  data.incomeBefore +
                  data.outgoingBefore +
                  customer?.balance || 0;
              const finalBalance = parseFloat(
                (balanceBefore + (balanceWithoutB || 0)).toFixed(2)
              );

              if (finalBalance >= 0) {
                this.sumDebit += finalBalance;
              } else {
                this.sumKredit += finalBalance;
              }

              return {
                ...data,
                customerGroupId: customer.customerGroupId,
                customerType: this._dataService
                  .customerGroups()
                  .find((c) => c.id == customer.customerGroupId)?.name,
                earlyBalance: parseFloat(
                  ((data.earlyBalance || 0) + (customer?.balance || 0)).toFixed(
                    2
                  )
                ),
                balance: parseFloat(balance?.toFixed(2) || 0),
                balanceBefore: parseFloat(balanceBefore.toFixed(2)),
                finalBalance,
              };
            });

            this._dataService.setLS("sumDebit", this.sumDebit);
            this._dataService.setLS("sumKredit", this.sumKredit);
            this.sumDebitSig.set(this.sumDebit);
            this.sumKreditSig.set(this.sumKredit);
            this._dataService.setLS("debitorsCreditors", result.slice(0, 50));

            return result;
          })
        );
      }),
      tap(() => {
        setTimeout(() => {
          this.dataLoading.set(false);
        }, 500);
      })
    );

    return merge(localObservable, combinedObservable);
  }

  async getCustomersBalanceById(customerId) {
    const combinedObservable = combineLatest([
      this._dataService.dateChanged,
      this._syncService.newDataSynced.pipe(distinctUntilChanged()),
      this.chipSelected.pipe(distinctUntilChanged()),
    ]).pipe(
      tap(() => {
        this.dataLoading.set(true);
      }),
      switchMap(async ([dateChanged, newDataSynced, chipSelected]) => {
        let startDate = new Date(
          this._dataService.startDate.value.format("YYYY-MM-DD")
        );
        let endDate = this._dataService.endDate.value.format("YYYY-MM-DD");

        const [stocks, sells, bank] = await Promise.all([
          db.stock.where("customerId").equals(customerId).toArray(),
          db.sells.where("customerId").equals(customerId).toArray(),
          db.bank.where("customerId").equals(customerId).toArray(),
        ]);

        const customerData: CustomerData = {};

        customerData[customerId] = {
          customerId: customerId,
          sellsTotal: 0,
          sellsBefore: 0,
          incomeTotal: 0,
          incomeBefore: 0,
          outgoingTotal: 0,
          outgoingBefore: 0,
          stocksTotal: 0,
          stocksBefore: 0,
        };

        const processData = (data, type) => {
          data.forEach((item) => {
            if (item.customerId) {
              const isAfterStartDate = new Date(item.date) >= startDate;
              const value = item.quantity * item.price;

              switch (type) {
                case "stock":
                  if (customerData[customerId]) {
                    customerData[customerId].stocksTotal +=
                      isAfterStartDate &&
                      value >= 0 &&
                      item.tType != "Production"
                        ? value
                        : 0;
                    customerData[customerId].sellsTotal +=
                      isAfterStartDate &&
                      value < 0 &&
                      item.tType != "Production"
                        ? -value
                        : 0;
                    customerData[customerId].stocksBefore +=
                      !isAfterStartDate &&
                      value >= 0 &&
                      item.tType != "Production"
                        ? value
                        : 0;
                    customerData[customerId].sellsBefore +=
                      !isAfterStartDate &&
                      value < 0 &&
                      item.tType != "Production"
                        ? -value
                        : 0;
                  }
                  break;

                case "sell":
                  if (customerData[customerId]) {
                    customerData[customerId].sellsTotal +=
                      isAfterStartDate && value >= 0 ? value : 0;
                    customerData[customerId].stocksTotal +=
                      isAfterStartDate && value < 0 ? -value : 0;

                    customerData[customerId].sellsBefore +=
                      !isAfterStartDate && value >= 0 ? value : 0;
                    customerData[customerId].stocksBefore +=
                      !isAfterStartDate && value <= 0 ? -value : 0;
                  }
                  break;

                case "bank":
                  if (customerData[customerId]) {
                    if (
                      item.income > 0 &&
                      this._dataService
                        .bankGroups()
                        .find(
                          (b) => b.id.toString() === item.bankGroupId.toString()
                        )?.includeInBalance
                    ) {
                      customerData[customerId].incomeTotal += isAfterStartDate
                        ? item.income
                        : 0;
                      customerData[customerId].incomeBefore += !isAfterStartDate
                        ? item.income
                        : 0;
                    } else if (
                      item.outgoings > 0 &&
                      this._dataService
                        .bankGroups()
                        .find(
                          (b) => b.id.toString() === item.bankGroupId.toString()
                        )?.includeInBalance
                    ) {
                      customerData[customerId].outgoingTotal += isAfterStartDate
                        ? item.outgoings
                        : 0;
                      customerData[customerId].outgoingBefore +=
                        !isAfterStartDate ? item.outgoings : 0;
                    }
                  }
                  break;
              }
            }
          });
        };

        processData(stocks, "stock");
        processData(sells, "sell");
        processData(bank, "bank");

        Object.values(customerData).forEach((data) => {
          data.stocksTotal = parseFloat(data.stocksTotal.toFixed(2));
          data.sellsTotal = parseFloat(data.sellsTotal.toFixed(2));
          data.incomeTotal = parseFloat(data.incomeTotal.toFixed(2));
          data.outgoingTotal = parseFloat(data.outgoingTotal.toFixed(2));
        });

        const filteredResult = Object.values(customerData);

        let result = [];
        filteredResult.forEach((data) => {
          let customer = this._dataService
            .customers()
            .find((c) => c.id == data.customerId);
          let balance =
            data.sellsTotal -
            data.stocksTotal -
            data.incomeTotal +
            data.outgoingTotal;
          let b = customer && customer?.balance ? customer.balance : 0; // balance
          let balanceBefore =
            data.sellsBefore -
            data.stocksBefore -
            data.incomeBefore +
            data.outgoingBefore +
            b;
          let pushData = {
            ...data,
            customerGroupId: customer?.customerGroupId || null,
            customerType: this._dataService
              .customerGroups()
              .find((c) => c.id == customer.customerGroupId)?.name,
            earlyBalance: parseFloat(
              ((data.earlyBalance || 0) + (customer.balance || 0)).toFixed(2)
            ),
            balance: parseFloat(balance?.toFixed(2) || 0),
            balanceBefore: parseFloat(balanceBefore.toFixed(2)),
            finalBalance: parseFloat(
              (balanceBefore + (balance || 0)).toFixed(2)
            ),
          };
          result.push(pushData);
        });

        return result;
      }),
      tap(() => {
        setTimeout(() => {
          this.dataLoading.set(false);
        }, 500);
      })
    );

    return combinedObservable;
  }

  async getCustomerOperations(customerId?) {
    let transactions = await db.transactions
      .where("customerId")
      .equals(customerId)
      .toArray();
    return transactions;
  }

  getCustomers(): Observable<IApiResult> {
    return this.http.get<IApiResult>(this.baseUrl);
  }

  getCustomerById(id: number) {
    return this.http.get(
      "https://tekoplast.az/hesabat/api.php/records/stock?join=customersorder=date,desc&join=materials&order=id,desc&filter=partnerId,eq," +
        id
    );
  }

  addCustomer(customer: ICustomer) {
    return this.http.post(this.baseUrl, customer);
  }

  updateCustomer(id: number, customer: ICustomer) {
    return this.http.put(this.baseUrl + id, customer);
  }

  deleteCustomer(id: number) {
    return this.http.delete(this.baseUrl + id);
  }
}

interface CustomerData {
  customerId?: number;
  customerName?: string;
  sellsTotal?: number;
  sellsBefore?: number;
  incomeTotal?: number;
  incomeBefore?: number;
  outgoingTotal?: number;
  outgoingBefore?: number;
  stocksTotal?: number;
  stocksBefore?: number;
  [key: string]: any;
}
