import { HttpClient } from "@angular/common/http";
import { Injectable, signal, inject, WritableSignal } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { BehaviorSubject, Observable, fromEvent, merge, of } from "rxjs";
import { map } from "rxjs/operators";
import { IApiResult } from "./shared/models/apiresult.interface";
import { AuthService } from "./auth.service";
import { ValueGetterParams } from "ag-grid-community";
import { MatDialog } from "@angular/material/dialog";
import { BankDialogComponent } from "./bank/bank-dialog/bank-dialog.component";
import { db } from "src/app/db.service";
import { AddStockDialogComponent } from "./stock/stock-dialog/stock-dialog.component";
import { SellsDialogComponent } from "./sells/sells-dialog/sells-dialog.component";
import { AddIstehsalDialogComponent } from "./products/add-istehsal-dialog/add-istehsal-dialog.component";
import { TransferBankDialogComponent } from "./dialogs/transfer-bank-dialog/transfer-bank-dialog.component";
import { StockTransferComponent } from "./dialogs/stock-transfer/stock-transfer.component";
import { SalaryDialogComponent } from "./bank/salary-dialog/salary-dialog.component";

import moment from "moment";
import * as _latinize from "latinize";
const latinize = _latinize;

@Injectable({
  providedIn: "root",
})
export class DataService {
  constructor(private http: HttpClient, private _authService: AuthService) {
    latinize.characters["ə"] = "e";
    latinize.characters["Ə"] = "E";
    latinize.characters["İ"] = "I";

    // this.online$ = merge(
    //   of(navigator.onLine),
    //   fromEvent(window, "online").pipe(map(() => true)),
    //   fromEvent(window, "offline").pipe(map(() => false))
    // );

    fromEvent(window, "online")
      .pipe(map(() => true))
      .subscribe(() => {
        this.onlineSubject.next(true); // Set true when online
      });

    fromEvent(window, "offline")
      .pipe(map(() => false))
      .subscribe(() => {
        this.onlineSubject.next(false); // Set false when offline
      });
  }

  dialog = inject(MatDialog);

  uuid;
  dbBase: string = "https://tekoplast.az/jjapi/";
  database: { id: number; name: string; db: string }[] = [
    { id: 0, name: "Karniz", db: "cavidneb_accounting" },
    { id: 1, name: "Bant", db: "cavidneb_bant" },
    { id: 2, name: "Profil", db: "cavidneb_jjtest" },
  ];

  dbTable: string = localStorage.hasOwnProperty("dbTable")
    ? JSON.parse(localStorage.getItem("dbTable"))
    : this.database[0].db;

  dbChanged = signal(this.dbTable);
  dbName = signal<any>(
    this.database.find((db) => db.db === this.dbTable)?.name
  );

  // FILTER DATA BY DATE
  startDate = localStorage.hasOwnProperty(this.dbTable + "startDate")
    ? new UntypedFormControl(
        moment(
          JSON.parse(localStorage.getItem(this.dbTable + "startDate")).value
        )
      )
    : new UntypedFormControl(moment().startOf("year"));

  endDate = localStorage.hasOwnProperty(this.dbTable + "endDate")
    ? new UntypedFormControl(
        moment(JSON.parse(localStorage.getItem(this.dbTable + "endDate")).value)
      )
    : new UntypedFormControl(moment().endOf("month"));

  agGridLocale = {
    loadingOoo: "Yüklənir...",
    noRowsToShow: "Seçilən tarix aralığında məlumat yoxdur",
  };

  latinizeText(params: ValueGetterParams, propertyName: string) {
    return latinize(params.data[propertyName].replace(/\s/g, ""));
  }

  dateRanges = [
    { label: "Bugün", start: moment(), end: moment(), active: false },
    {
      label: "Dünən",
      start: moment().subtract(1, "days"),
      end: moment().subtract(1, "days"),
      active: false,
    },
    {
      label: "Bu həftə",
      start: moment().startOf("isoWeek"),
      end: moment().endOf("isoWeek"),
      active: false,
    },
    {
      label: "Öt.həftə",
      start: moment().subtract(1, "week").startOf("isoWeek"),
      end: moment().subtract(1, "week").endOf("isoWeek"),
      active: false,
    },
    {
      label: "Bu ay",
      start: moment().startOf("month"),
      end: moment().endOf("month"),
      active: false,
    },
    {
      label: "Ötən ay",
      start: moment().subtract(1, "month").startOf("month"),
      end: moment().subtract(1, "month").endOf("month"),
      active: false,
    },
    {
      label: "Bu il",
      start: moment().startOf("year"),
      end: moment().endOf("year"),
      active: false,
    },
    {
      label: "Ötən il",
      start: moment().startOf("year").subtract(1, "year"),
      end: moment().endOf("year").subtract(1, "year"),
      active: false,
    },
  ];

  dateChanged: BehaviorSubject<string> = new BehaviorSubject("1");
  dateChangedSig = signal(new Date().toDateString());
  showSideNav = localStorage.hasOwnProperty("sideNavOpenState")
    ? localStorage.getItem("sideNavOpenState")
    : true;
  showNav: boolean = false; //show navbar collapse menu on mobile
  showContextMenu: boolean = false;
  cropNumber;
  dataLoading: boolean = true;
  dataLoadingSig: WritableSignal<boolean> = signal(false);
  units: string[] = ["ədəd", "kg", "m", "m2"];
  onlineSubject = new BehaviorSubject<string | boolean>(navigator.onLine);
  online$: Observable<string | boolean> = this.onlineSubject.asObservable();
  mobileDevice = /Mobi|Android/i.test(navigator.userAgent);

  customers = signal(this.getLS("customers") ?? []);
  customerGroups = signal(this.getLS("customerGroups") ?? []);
  products = signal(this.getLS("products") ?? []);
  bankGroups = signal(this.getLS("bankGroups") ?? []);
  materials = signal(this.getLS("materials") ?? []);
  stockGroups = signal(this.getLS("stockGroups") ?? []);
  formulaGroups = signal(this.getLS("formulaGroups") ?? []);
  todos = signal(this.getLS("todos") ?? []);
  cashBox = signal(this.getLS("cashBox") ?? []);
  users = signal(this.getLS("users") ?? []);
  errors = signal(this.getLS("errors") ?? []);

  bankGroupCategories = signal([
    { id: 1, name: "Xammal" },
    { id: 2, name: "Xərclər" },
    { id: 3, name: "Mənfəətə daxil olmayan xərclər" },
    { id: 4, name: "Mənfəət" },
    { id: 5, name: "Depozit" },
  ]);

  setLS(key, value) {
    localStorage.setItem(this.dbTable + key, JSON.stringify(value));
  }

  getLS(key) {
    return localStorage.hasOwnProperty(this.dbTable + key)
      ? JSON.parse(localStorage.getItem(this.dbTable + key))
      : null;
  }

  getFormulaGroups(): Observable<any> {
    return this.http
      .get<IApiResult>(
        this.dbBase +
          "api.php/records/formulaGroups?join=formulas&join=materials"
      )
      .pipe(
        map((res) => {
          return res.records;
        })
      );
  }

  getCustomers(): Observable<any> {
    return this.http
      .get<IApiResult>(this.dbBase + "api.php/records/customers")
      .pipe(
        map((res) => {
          return res.records;
        })
      );
  }

  getCustomerGroups(): Observable<any> {
    return this.http
      .get<IApiResult>(this.dbBase + "api.php/records/customerGroups")
      .pipe(
        map((res) => {
          return res.records;
        })
      );
  }

  getMaterials() {
    return this.http
      .get<IApiResult>(this.dbBase + "api.php/records/materials")
      .pipe(
        map((res) => {
          return res.records;
        })
      );
  }

  getStockGroups(): Observable<any> {
    return this.http
      .get<IApiResult>(this.dbBase + "api.php/records/stockGroups")
      .pipe(
        map((res) => {
          return res.records;
        })
      );
  }

  getProducts(): Observable<any> {
    return this.http
      .get<IApiResult>(this.dbBase + "api.php/records/products")
      .pipe(
        map((res) => {
          return res.records;
        })
      );
  }

  getBankDetails(): Observable<any> {
    return this.http.get<IApiResult>(this.dbBase + "api/?getBankDetails").pipe(
      map((res) => {
        return res;
      })
    );
  }

  getBankGroups(): Observable<any> {
    return this.http
      .get<IApiResult>(this.dbBase + "api.php/records/bankGroups")
      .pipe(
        map((res) => {
          return res.records;
        })
      );
  }

  getCashBox(): Observable<any> {
    return this.http
      .get<IApiResult>(this.dbBase + "api.php/records/cashBox")
      .pipe(
        map((res) => {
          return res.records;
        })
      );
  }

  getTodos(): Observable<any> {
    return this.http
      .get<IApiResult>(
        this.dbBase +
          `api.php/records/todos?filter=userId,eq,${this._authService.loggedInUserValue.id}`
      )
      .pipe(
        map((res) => {
          return res.records;
        })
      );
  }

  getUsers(): Observable<any> {
    return this.http
      .get<IApiResult>(this.dbBase + `api.php/records/users`)
      .pipe(
        map((res) => {
          return res.records;
        })
      );
  }

  getErrors(): Observable<any> {
    return this.http
      .get<IApiResult>(this.dbBase + `api.php/records/errors`)
      .pipe(
        map((res) => {
          return res.records;
        })
      );
  }

  // SEARCH ITEMS FOR NG SELECT, LATINIZE CHARS
  customSearchFn(searchTerm, item) {
    let category = item.category
      ? latinize(item.category).replace(/\s/g, "").toLowerCase()
      : "";
    let name = latinize(item.name).replace(/\s/g, "").toLowerCase() + category;
    searchTerm = latinize(searchTerm).replace(/\s/g, "").toLowerCase();
    return name?.includes(searchTerm);
  }

  formatNumber(number) {
    return Number.isInteger(number) ? number.toFixed(0) : number.toFixed(2);
  }

  scrollToTable() {
    document.getElementById("targetTable").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  trackByFn(i: number) {
    return i;
  }

  findObjectById(id, obj) {
    return obj.find((item) => item.id === id);
  }

  formatDateValue(date) {
    if (date.value.length == 2 || date.value.length == 5) {
      date.value += "/";
    }
    if (date.value.length == 6) {
      if (
        date.value.slice(-3, -1) >
        parseInt((new Date().getMonth() + 1).toString().padStart(2, "0"))
      ) {
        date.value += new Date().getFullYear() - 1;
      } else {
        date.value += new Date().getFullYear();
      }
    }
    // if (date.value.length == 11) {
    //   console.log("this");
    //   date.value = date.value.slice(0, -3) + date.value.slice(-1);
    // }
  }

  fixInputValue(inputString) {
    let result = "";

    for (let i = 0; i < inputString.length; i++) {
      const char = inputString[i];

      if (
        !isNaN(parseFloat(char)) ||
        char === "=" ||
        char === "." ||
        char === "/" ||
        char === "*" ||
        char === "+" ||
        char === "-"
      ) {
        result += char;
      } else if (char === "x" || char === "X") {
        result += "*";
      } else {
        result += ".";
      }
    }

    return result;
  }

  getNumericsOnly(event) {
    const charCode = event.which ? event.which : event.keyCode;
    const charStr = String.fromCharCode(charCode);

    let strArr = event.target.value.split(/[-+*/]/);
    let lastNumIsDecimal = strArr[strArr.length - 1].includes(".");

    const allowedChars =
      event.target.value.length &&
      event.target.value[0] == "=" &&
      lastNumIsDecimal
        ? /^[0-9\/\*\\x\+\-]$/
        : event.target.value.length && event.target.value[0] == "="
        ? /^[0-9.\/\*\,\\x\+\-]$/
        : !event.target.value.length
        ? /^[0-9=]$/
        : event.target?.value?.includes(".")
        ? /^[0-9]$/
        : /^[0-9.,]$/;

    const previousChar = event.target.value[event.target.selectionStart - 1];
    const isValidChar = (char) => /^[.\/\*\,\\x\+\-=]$/.test(char);

    if (
      !allowedChars.test(charStr) ||
      (isValidChar(previousChar) && isNaN(Number(charStr)))
    ) {
      event.preventDefault();
    }
  }

  calculateInput(calculation) {
    if (calculation && calculation !== undefined && calculation !== null) {
      if (calculation?.toString().includes("=")) {
        const expression: string = calculation
          .substring(calculation.indexOf("=") + 1)
          .trim();
        try {
          const result = parseFloat(eval(expression).toFixed(3));
          return !isNaN(result) && isFinite(result) ? result : 0;
        } catch (error) {
          return 0;
        }
      } else {
        let numericValue = 0;
        if (calculation) {
          numericValue = Number(
            parseFloat(
              calculation
                ?.toString()
                .replace(/,/g, ".")
                .replace(/x/g, "*")
                .replace(/[^\d.-]/g, "")
            )
          );
        }
        return !isNaN(numericValue) ? numericValue : 0;
      }
    }
  }

  storeErrorInDatabase(
    error: any,
    transaction: string,
    data: string,
    formData: string,
    dbTable: string
  ) {
    return this.http.post(this.dbBase + "api.php/records/errors", {
      message: error?.message ?? "Error",
      stackTrace: JSON.stringify(error) ?? "",
      transaction: transaction ?? "",
      data: data ?? "",
      formData: formData ?? "",
      date:
        new UntypedFormControl(moment()).value.format("YYYY-MM-DD HH:mm:ss") ??
        "",
      dbTable: dbTable ?? "",
      user: this._authService.loggedInUserValue.fullname ?? "",
    });
  }

  setActiveClassOnDates() {
    const storedStartDate = this.startDate.value;
    const storedEndDate = this.endDate.value;

    if (storedStartDate && storedEndDate) {
      this.dateRanges.forEach((range) => {
        if (
          range.start.startOf("day").isSame(storedStartDate.startOf("day")) &&
          range.end.startOf("day").isSame(storedEndDate.startOf("day"))
        ) {
          range.active = true;
        } else {
          range.active = false;
        }
      });
    }
  }

  async transactionDialog(transaction) {
    console.log(transaction);

    // KASSA
    if (transaction.type == "Bank") {
      let dialogData: {
        type?: string;
        data?: Object;
        cashboxId?: number;
        transactionId?: number;
        details?: string;
      } = {};
      let rowData = await db.bank.where("tId").equals(transaction.id).first();
      rowData.details = transaction.details;
      if (rowData) {
        rowData.bankGroupName = rowData.bankGroup;
        rowData.transactionId = rowData.tId;
        dialogData.type = rowData.income ? "medaxil" : "";
        dialogData.cashboxId = rowData.cashboxId || 1;
        dialogData.data = rowData;
        rowData.transaction = transaction;
      }
      dialogData.data = rowData;
      this.dialog.open(BankDialogComponent, {
        width: "800px",
        maxHeight: "700px",
        data: dialogData,
        closeOnNavigation: true,
        panelClass: "materialDialog",
      });
    }

    // KASSA TRANSFER
    if (transaction.type == "Cashbox Transfer") {
      let dialogData: {
        type?: string;
        data?: Object;
        cashboxId?: number;
        transactionId?: number;
        details?: string;
      } = {};
      let rowData = await db.bank.where("tId").equals(transaction.id).first();
      rowData.details = transaction.details;
      if (rowData) {
        rowData.bankGroupName = rowData.bankGroup;
        rowData.transactionId = rowData.tId;
        rowData.transaction = transaction;
        dialogData.type = rowData.income ? "medaxil" : "";
        dialogData.cashboxId = rowData.cashboxId || 1;
        dialogData.data = rowData;
      }
      dialogData.data = rowData;
      this.dialog.open(TransferBankDialogComponent, {
        width: "800px",
        maxHeight: "700px",
        data: dialogData,
        closeOnNavigation: true,
        panelClass: "materialDialog",
      });
    }

    // SALARY
    if (transaction?.type == "Salary") {
      let dialogData = {
        // cashboxId: this._bankService.selectedCashboxId(),
        transactionId: transaction.id,
      };
      this.dialog.open(SalaryDialogComponent, {
        width: "800px",
        maxHeight: "700px",
        data: dialogData,
        panelClass: "materialDialog",
        closeOnNavigation: true,
        disableClose: this.mobileDevice ? false : dialogData ? false : true,
      });
    }

    // STOCK
    if (transaction.type == "Stock Entry" || transaction.type == "Stock Out") {
      let transactions = await db.stock
        .where("tId")
        .equals(transaction.id)
        .toArray();
      transaction.tId = transaction.id;
      transaction.stockGroupId = transactions[0].stockGroupId || null;

      console.log(transactions);

      this.dialog.open(AddStockDialogComponent, {
        width: "1000px",
        data: {
          data: transaction,
          stockEntryType: transaction.type,
          stockGroupId: transaction.stockGroupId,
          transactions,
        },
        panelClass: "materialDialog",
        closeOnNavigation: true,
      });
    }

    if (transaction.type == "Stock Transfer") {
      let transactions = await db.stock
        .where("tId")
        .equals(transaction.id)
        .toArray();
      transaction.tId = transaction.id;
      transaction.stockGroupId = transactions[0].stockGroupId || null;
      let dialogData = {
        data: transaction,
        stockEntryType: transaction.type,
        stockGroupId: transaction.stockGroupId,
        transactions,
      };
      this.dialog.open(StockTransferComponent, {
        width: "700px",
        maxHeight: "700px",
        data: dialogData,
        panelClass: "materialDialog",
        closeOnNavigation: true,
        disableClose: this.mobileDevice ? false : dialogData ? false : true,
      });
    }

    // SELLS
    if (transaction.type == "Sells" || transaction.type == "Return") {
      let data = null;
      data = transaction;
      data.transactionId = transaction.id;

      this.dialog.open(SellsDialogComponent, {
        width: "1000px",
        maxHeight: "700px",
        data: data,
        closeOnNavigation: true,
      });
    }

    // PRODUCTION
    if (transaction.type == "Production") {
      let data = await db.production
        .where("tId")
        .equals(transaction.id)
        .first();
      this.dialog.open(AddIstehsalDialogComponent, {
        width: "850px",
        maxHeight: "700px",
        data: data,
        panelClass: "materialDialog",
        closeOnNavigation: true,
      });
    }
  }

  getTransactionType(type, transaction) {
    switch (type) {
      case "Bank":
        return transaction.sum < 0 ? "Kassa" : "Kassa";
      case "Cashbox Transfer":
        return "Köçürmə";
      case "Return":
        return "Qayıtma";
      case "Sells":
        return "Satış";
      case "Stock Entry":
        return "Anbar";
      case "Stock Out":
        return "Anbar";
      case "Stock Transfer":
        return "Transfer";
      case "Production":
        return "İstehsal";
      case "Salary":
        return "Maaş";
      default:
        return type;
    }
  }
}
